import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import Stack from '@mui/material/Stack';
import { CurrencyFilter } from '../../filters';
import { useGetUser, useGetCurrenciesFromCompany } from '../../../hooks';
import currencyVar from '../../../apollo/reactive-variables/money';
import {
  preofferSelected,
  offerSelected,
} from '../../../apollo/reactive-variables/invoices';
import {
  orderingSelected,
  orderingOfferSelected,
} from '../../../apollo/reactive-variables/purchase-orders';

const CurrencyNavItem = () => {
  const user = useGetUser();

  const currencies = user?.selectedCompany
    ? useGetCurrenciesFromCompany({
      masterEntity: user.selectedCompany?.masterEntity,
    })
    : null;

  const currency = useReactiveVar(currencyVar);

  useEffect(() => {
    preofferSelected([]);
    offerSelected([]);
    orderingSelected([]);
    orderingOfferSelected([]);
  }, [currency]);

  return (
    <Stack direction="row" alignItems="center">
      {user?.selectedCompany ? (
        <CurrencyFilter
          currencies={currencies}
          currency={currency}
          setCurrency={currencyVar}
          sx={{ '& .MuiSelect-select': { padding: '4px 10px 4px 14px' } }}
        />
      ) : null}
    </Stack>
  );
};

export default CurrencyNavItem;
