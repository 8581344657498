import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const TermsAndConditionsLink = () => (
  <Stack direction="column" alignItems="center" spacing={0.2}>
    <Typography variant="body1" color="text.primary" textAlign="center">
      Al ingresar declaras haber leído y aceptado los
    </Typography>
    <Link
      target="_blank"
      to="/app/profile/terms"
      component={RouterLink}
      color="text.primary"
      fontWeight={700}
      underline="hover"
      sx={{ textDecoration: 'none' }}
    >
      “Términos y condiciones”
    </Link>
  </Stack>
);

export default TermsAndConditionsLink;
