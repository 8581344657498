import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Box } from '@mui/system';

import dayjs from '../../config/dayjs';
import { useIsMobile } from '../../hooks';
import { FingoDataGrid } from '../dataGrids';
import { PaperHeader } from '../headers';

import { WhatIsFingo } from '../../assets';
import { fakeRows } from '../../constants';
import Blinker from '../animations/Blink';
import { DocumentFilters } from '../filters';

const FakeDocumentList = ({
  type,
  headerTitle,
  includeHeaders,
  mobileHeaders,
  customSummaryElement,
  preColumns,
  openJoyride,
  setRegisterDialog,
}) => {
  const isMobile = useIsMobile();

  const columns = preColumns.map((col) => {
    if (col.type === 'number') {
      return {
        ...col,
        filterOperators: getGridNumericOperators().filter((operator) => ['=', '>', '>=', '<', '<='].includes(operator.value)),
      };
    }
    if (col.type === 'date') {
      return {
        ...col,
        filterOperators: getGridDateOperators().filter((operator) => ['is', 'after', 'onOrAfter', 'before', 'onOrBefore'].includes(
          operator.value,
        )),
      };
    }
    if (
      col.field === 'company_MasterEntity_Name'
      || col.field === 'receiver_Name'
    ) {
      return {
        ...col,
        filterOperators: getGridStringOperators().filter((operator) => ['equals', 'contains', 'startsWith'].includes(operator.value)),
      };
    }
    if (col.type === 'string') {
      return {
        ...col,
        filterOperators: getGridStringOperators().filter((operator) => ['equals', 'isAnyOf'].includes(operator.value)),
      };
    }
    if (col.type === 'singleSelect') {
      return {
        ...col,
        filterOperators: getGridSingleSelectOperators().filter((operator) => ['is', 'isAnyOf'].includes(operator.value)),
      };
    }
    return col;
  });

  const dataGridColumns = columns
    .filter((column) => includeHeaders.includes(column.field))
    .filter((column) => (isMobile ? mobileHeaders.includes(column.field) : true));

  const dates = {
    dateIssued_Gte: dayjs().subtract(2, 'month'),
    dateIssued_Lte: dayjs(),
  };
  const [states, setStates] = useState({
    siiStatus: ['Rejected'],
    documentFinanceStatus: [],
  });
  const [rows, setRows] = useState(fakeRows);

  useEffect(() => {
    if (states.siiStatus.length > 0) {
      setRows(fakeRows.filter((row) => row.siiStatus.includes('REJECTED')));
    } else if (states.documentFinanceStatus.length > 0) {
      setRows(fakeRows.filter((row) => row.siiStatus.includes('RECEPTION')));
    } else {
      setRows(fakeRows);
    }
  }, [states]);
  return (
    <>
      <PaperHeader
        type={type}
        viewTitle={headerTitle}
        finder={{
          searchPlaceHolder: 'Búsqueda por folio, rut o nombres',
          canPasteExcel: true,
          onFinderChange: () => {},
          finderValue: '',
        }}
        openJoyride={openJoyride}
      />

      <DocumentFilters
        dates={dates}
        showQuickFilters
        canPasteExcel
        showRefresh
        states={states}
        setStates={setStates}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', my: 1, mx: 2 }}>
        <Blinker
          isBlinking
          CustomComponent={Box}
          softIntensity
          component="img"
          src={WhatIsFingo}
          alt="alt"
          sx={{
            width: '100%',
            height: 'auto',
            cursor: 'pointer',
            borderRadius: '5px',
          }}
          onClick={() => {
            setRegisterDialog(true);
          }}
        />
      </Box>
      {customSummaryElement}
      <FingoDataGrid
        rows={rows}
        columns={dataGridColumns}
        rowCount={fakeRows.length}
      />
    </>
  );
};

FakeDocumentList.propTypes = {
  type: PropTypes.string.isRequired,
  headerTitle: PropTypes.string.isRequired,
  setRegisterDialog: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  preColumns: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  includeHeaders: PropTypes.arrayOf(PropTypes.string),
  mobileHeaders: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  customSummaryElement: PropTypes.element,
  // Function that receives row and newValue
  openJoyride: PropTypes.func,
};

FakeDocumentList.defaultProps = {
  includeHeaders: [],
  preColumns: [],
  mobileHeaders: [],
  customSummaryElement: null,
  openJoyride: undefined,
};

export default FakeDocumentList;
